import chatService from '@/services/chat.js';
import jwtDecode from 'jwt-decode';
import eventBus from '@/main-event-bus.js';
import moment from 'moment-timezone';
const bell = new Audio(require('@/assets/sounds/message-alert.mp3'));

function getCookie(name) {
  var nameEQ = name + '=';
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export default {
  namespaced: true,
  state: {
    contactDataHasBeenUpdated: false,
    loseSocketConnection: false,
    socketConnected: false,
    activeRoomId: null,
    activeRoom: null,
    socket: null,
    roomsWithUnreadedMessages: {},
    displayedMessageStatuses: [],
    transferenceSet: [],
    invitations: [],
    rooms: [],
    mostRecentMessagesMap: {},
  },
  mutations: {
    trackMostRecentMessage(state, { chatRoomId, timestamp }) {
      const newMap = { ...state.mostRecentMessagesMap };
      newMap[chatRoomId] = timestamp;
      state.mostRecentMessagesMap = newMap;
    },
    addDisplayedMessages(state, { activeRoomMemberId, ...message }) {
      const agentStatusInMessage = message?.status.find(
        (messageStatus) => messageStatus.chatRoomMemberId === activeRoomMemberId,
      );
      if (!agentStatusInMessage) return;

      const alreadyDisplayed = state.displayedMessageStatuses.some((status) => status.id === agentStatusInMessage.id);

      if (agentStatusInMessage.status !== 'read' && !alreadyDisplayed) {
        state.displayedMessageStatuses.push({
          ...agentStatusInMessage,
          status: 'read',
        });
      }
    },
    useLocalStorageDisplayedMessageStatuses(state, payload) {
      state.displayedMessageStatuses = payload;
    },
    resetDisplayedMessageStatuses(state) {
      state.displayedMessageStatuses = [];
    },
    resetActiveRoomMessages(state) {
      state.activeRoom.messages = [];
    },
    addActiveRoomMessages(state, messages) {
      messages.forEach((message) => {
        state.activeRoom.messages.push(message);
      });
    },
    addActiveRoomMessage(state, message) {
      if (!state?.activeRoom?.messages) return;
      if (state.activeRoomId != message.chatRoomId) return;
      state.activeRoom.messages.unshift(message);
    },
    setSocket(state, socket) {
      state.socket = socket;
    },
    setLoseSocketConnection(state, boolean) {
      state.loseSocketConnection = boolean;
    },
    setSocketConnected(state, boolean) {
      state.socketConnected = boolean;
    },
    deactivateRoom(state, roomId) {
      if (state.activeRoomId !== roomId) return;
      state.activeRoomId = null;
      state.activeRoom = null;
    },
    setActiveRoom(state, { roomId, room }) {
      state.activeRoom = room;
      state.activeRoomId = roomId;
    },
    setInvitations(state, invitations) {
      state.invitations = invitations;
    },
    addTransference(state, roomId) {
      state.transferenceSet.push(roomId);
    },
    removeTransference(state, roomId) {
      state.transferenceSet = state.transferenceSet.filter((transferenceId) => {
        return transferenceId != roomId;
      });
    },
    addInvitation(state, invitation) {
      state.invitations.push(invitation);
    },
    removeInvitationById(state, invitationId) {
      state.invitations = state.invitations.filter((invitation) => {
        return invitation.id !== invitationId;
      });
    },
    addRoom(state, room) {
      state.rooms.unshift(room);
    },
    setRooms(state, rooms) {
      state.rooms = rooms;
    },
    removeRoomById(state, roomId) {
      state.rooms = state.rooms.filter((room) => {
        return room.id !== roomId;
      });
      state.transferenceSet = state.transferenceSet.filter((transferenceId) => {
        return transferenceId != roomId;
      });
    },
    addMessageToActiveRoom(state, message) {
      message.reactions ||= [];
      for (let index = 0; index < state.rooms.length; index++) {
        const room = state.rooms[index];
        if (room.id !== message.chatRoomId) continue;
        else {
          room.messages.unshift(message);
          break;
        }
      }
    },
    changeActiveRoomMessageStatus(state, payload) {
      const { activeRoom, activeRoomId } = state;
      const messagesMap = new Map(activeRoom?.messages?.map((message) => [message.id, message]));

      for (const { chatRoomId, chatRoomMessageId, chatRoomMemberId, status } of payload) {
        if (activeRoomId !== chatRoomId || !messagesMap.has(chatRoomMessageId)) continue;

        const messageToUpdate = messagesMap.get(chatRoomMessageId);
        const statusToUpdate = messageToUpdate?.status?.find((status) => status.chatRoomMemberId === chatRoomMemberId);

        if (statusToUpdate) statusToUpdate.status = status;
      }
    },
    updateMessageReaction(state, payload) {
      const { chatRoomId, chatRoomMessageId, chatRoomMemberId, emoji } = payload;

      const roomToUpdate = state.rooms.find((room) => room.id === chatRoomId);
      if (!roomToUpdate) return;

      const messageToUpdate = roomToUpdate.messages.find((message) => message.id === chatRoomMessageId);
      if (!messageToUpdate) return;

      if (emoji === null) {
        messageToUpdate.reactions = messageToUpdate.reactions.filter(
          (reaction) => reaction.chatRoomMemberId !== chatRoomMemberId,
        );
      } else {
        const existingReaction = messageToUpdate?.reactions?.find(
          (reaction) => reaction.chatRoomMemberId === chatRoomMemberId,
        );

        if (existingReaction) {
          existingReaction.emoji = emoji;
        } else {
          messageToUpdate.reactions = [payload];
        }
      }
    },
    updateContactDataHasBeenUpdated(state, isUpdated) {
      state.contactDataHasBeenUpdated = isUpdated;
    },
    addMemberToActiveRoom(state, { id, members }) {
      if (!state.activeRoom) return;
      if (id !== state.activeRoom.id) return;

      state.activeRoom.members = members;
    },
    removeMemberFromActiveRoom(state, member) {
      if (!state.activeRoom) return;
      if (member.chatRoomId !== state.activeRoom.id) return;

      const memberInRoom = state.activeRoom.members.find((roomMember) => roomMember.id === member.id);
      if (!memberInRoom) return;
      memberInRoom.exited = true;
    },
    setRoomsWithUnreadedMessages(state, rooms) {
      const hash = {};
      rooms.forEach((room) => {
        hash[room.chatRoomId] = room.unreadMessages;
      });
      state.roomsWithUnreadedMessages = hash;
    },
    addRoomWithUnreadedMessages(state, room) {
      const updatedRoomsWithUnreadedMessages = state.roomsWithUnreadedMessages;
      updatedRoomsWithUnreadedMessages[room.chatRoomId] = room.unreadMessages;
      state.roomsWithUnreadedMessages = JSON.parse(JSON.stringify(updatedRoomsWithUnreadedMessages));
    },
    removeRoomWithUnreadedMessages(state, chatRoomId) {
      const hash = state.roomsWithUnreadedMessages;
      delete hash[chatRoomId];
      state.roomsWithUnreadedMessages = JSON.parse(JSON.stringify(hash));
    },
    increaseUnreadMessagesCountByRoomId(state, chatRoomId) {
      const copyRoomsWithUnreadedMessages = { ...state.roomsWithUnreadedMessages };
      if (!copyRoomsWithUnreadedMessages[chatRoomId]) copyRoomsWithUnreadedMessages[chatRoomId] = 0;
      copyRoomsWithUnreadedMessages[chatRoomId]++;
      state.roomsWithUnreadedMessages = copyRoomsWithUnreadedMessages;
    },
    decreaseUnreadedMessagesCountByRoomId(state, chatRoomId, amount = 1) {
      if (!state.roomsWithUnreadedMessages[chatRoomId]) return;
      const copyRoomsWithUnreadedMessages = { ...state.roomsWithUnreadedMessages };
      copyRoomsWithUnreadedMessages[chatRoomId] = Math.max(copyRoomsWithUnreadedMessages[chatRoomId] - amount, 0);
      if (copyRoomsWithUnreadedMessages[chatRoomId] == 0) delete copyRoomsWithUnreadedMessages[chatRoomId];
      state.roomsWithUnreadedMessages = copyRoomsWithUnreadedMessages;
    },
    addNewTagsToActiveChat(state, tags) {
      if (!state.activeRoom?.tags?.length) {
        state.activeRoom.tags = tags;
        return;
      }
      tags.forEach((tag) => {
        state.activeRoom.tags.unshift(tag);
      });
    },
    removeTagFromActiveChat(state, tagId) {
      if(!state.activeRoom?.tags?.length) return;
      state.activeRoom.tags = state.activeRoom?.tags?.filter((tag) => tag.id != tagId);
    },
  },
  getters: {
    getRoomsWithUnreadedMessages(state) {
      return state.roomsWithUnreadedMessages;
    },
    getRoomsWithUnreadedMessagesCount(state) {
      if (!state.roomsWithUnreadedMessages) return 0;
      return Object.keys(state.roomsWithUnreadedMessages);
    },
    getMostRecentMessageTimestamp(state) {
      return (roomId) => state.mostRecentMessagesMap[roomId];
    },
    getUnreadedMessagesCountByRoomId(state) {
      return (roomId) => state.roomsWithUnreadedMessages[roomId];
    },
    getDisplayedMessageStatuses(state) {
      return state.displayedMessageStatuses;
    },
    socket(state) {
      return state.socket;
    },
    socketConnected(state) {
      return state.socketConnected;
    },
    loseSocketConnection(state) {
      return state.loseSocketConnection;
    },
    rooms(state) {
      state.rooms = state.rooms.map((room) => {
        return {
          ...room,
          pending: room.pending,
          isLastMessageFromOperator: !room.messages?.length
            ? 'noMessages'
            : room.messages[room.messages.length - 1].provider == 'opens',
        };
      });
      return state.rooms;
    },
    activeRoom(state) {
      return {
        room: state.activeRoom,
        id: state.activeRoomId,
      };
    },
    invites(state) {
      return state.invitations;
    },
    transferenceSet(state) {
      return state.transferenceSet;
    },
    activeRoomMessages(state) {
      if (!state.activeRoom) return [];
      return state.activeRoom.messages;
    },
    contactDataHasBeenUpdated(state) {
      return state.contactDataHasBeenUpdated;
    },
    activeRoomRecipientMember(state) {
      return state?.activeRoom?.members?.find((member) => {
        return member.provider?.toLowerCase() !== 'opens';
      });
    },
  },
  /**
   * @type {import('vuex').ActionTree}
   */
  actions: {
    initialize({ commit, rootGetters, state, getters }, socket) {
      const cookie = getCookie('snep7');
      const decoded = jwtDecode(cookie);
      commit('setSocket', socket);
      socket.on('connect', () => {
        commit('setSocketConnected', true);
      });
      socket.on('disconnect', () => {
        commit('setLoseSocketConnection', true);
        commit('setSocketConnected', false);
      });
      socket.on('invitation', (invitation) => {
        const user = rootGetters['user/GET_USER'];
        bell.volume = rootGetters['userPreferences/getAudioVolume'];
        const showMessagesNotification = localStorage.getItem('@yunique:show-messages-notification');
        if (!user.oncall && showMessagesNotification == 'true') bell.play();
        eventBus.$emit('UX-event', { eventName: 'invitation-received', invitation });
        const invitations = getters.invites;
        const alreadyInvited = invitations.some((inv) => {
          const isSameRoom = inv.chatRoomId === invitation.chatRoomId;
          const isSameType = inv.type === invitation.type;
          const isSameDelegator = inv.delegatorId === invitation.delegatorId;

          return isSameRoom && isSameType && isSameDelegator;
        });

        let oldTitle = '';
        if (!Object.keys(state.roomsWithUnreadedMessages).length) oldTitle = rootGetters['GET_CURRENT_PAGE_TITLE'];
        else
          oldTitle = `(${Object.keys(state.roomsWithUnreadedMessages).length}) ${
            rootGetters['GET_CURRENT_PAGE_TITLE']
          }`;

        if (!alreadyInvited) commit('addInvitation', invitation);
        if (!user.oncall && showMessagesNotification == 'true')
          notifyMe('Atenção!', 'Aguardando resposta para um novo atendimento de chat');

        if (document.hidden) {
          const blinkTitleInterval = setInterval(() => {
            document.title = document.title == oldTitle ? 'Novo convite de chat' : oldTitle;
          }, 1000);
          document.addEventListener('visibilitychange', () => {
            if (!document.hidden) {
              document.title = oldTitle;
              return clearInterval(blinkTitleInterval);
            }
          });
        }
      });
      /**
       *  @typedef ChatInvitation
       * @property {number} id
       * @property {number} chatRoomId
       * @property {string} companyId
       * @property {boolean} replied
       * @property {boolean} expired
       * @property {string} opensUserId
       * @property {string} delegatorId
       * @property {'transference'|'invitation'|'system'} type
       * @property {null} expiresAt
       * @property {string} createdAt
       * @property {string} updatedAt
       */

      /**
       * @param {ChatInvitation} reply
       */
      const replyHandler = (reply) => {
        eventBus.$emit('UX-event', { eventName: 'invitation-replied', reply });
        if (reply.type !== 'transference') return;
        commit('deactivateRoom', reply.chatRoomId);
        commit('removeRoomById', reply.chatRoomId);
      };
      socket.on('reply', replyHandler);
      socket.on('uninvited', (invitation) => {
        eventBus.$emit('UX-event', { eventName: 'invitation-canceled', invitation });
        commit('removeInvitationById', invitation.id);
      });
      socket.on('chat.message.status', (message) => {
        commit('changeActiveRoomMessageStatus', message);
      });
      socket.on('message-reaction-update', (message) => {
        commit('updateMessageReaction', message);
      });

      socket.on('company:member-entry', (payload) => {
        commit('addMemberToActiveRoom', payload);
      });

      socket.on('company:member-exit', (payload) => {
        commit('removeMemberFromActiveRoom', payload);
      });

      socket.on('chat-room-terminated', async (payload) => {
        let invitationsCopy = [...state.invitations];
        invitationsCopy = invitationsCopy.filter((invitation) => invitation.chatRoomId != payload.id);
        state.invitations = invitationsCopy;
      });

      chatService
        .getAllInvitations({
          opensUserId: decoded.user_id,
          companyId: decoded.company_id,
          replied: false,
          expired: false,
        })
        .then((data) => {
          commit('setInvitations', data);
        })
        .catch((e) => {});
    },
    async reconnectSocket({ commit, state }) {
      const cookie = getCookie('snep7');
      const decoded = jwtDecode(cookie);
      const socket = state.socket;
      socket.emit('join-room', decoded.user_id);
      socket.emit('join-room', `chat-${decoded.company_id}`);
      const invitations = await chatService.getAllInvitations({
        opensUserId: decoded.user_id,
        companyId: decoded.company_id,
        replied: false,
        expired: false,
      });
      commit('setInvitations', invitations);
      commit('setLoseSocketConnection', false);
    },
    async markMessagesAsRead({ commit, state }, displayedMessageStatuses = state.displayedMessageStatuses) {
      if (!displayedMessageStatuses.length) return;
      const payload = {
        chatRoomId: displayedMessageStatuses[0].chatRoomId,
        chatRoomMemberId: displayedMessageStatuses[0].chatRoomMemberId,
        chatRoomMessageId: displayedMessageStatuses.map((status) => status.chatRoomMessageId),
        status: 'read',
      };

      commit('resetDisplayedMessageStatuses');
      const { updatedMessageStatus } = await chatService.updateMessageStatus(payload);
      if (!updatedMessageStatus.length) return;

      commit(
        'decreaseUnreadedMessagesCountByRoomId',
        displayedMessageStatuses[0].chatRoomId,
        updatedMessageStatus.length,
      );
      commit('changeActiveRoomMessageStatus', updatedMessageStatus);
    },
  },
};

function notifyMe(title, message) {
  if (!document.hidden && document.hasFocus()) return;
  if (Notification.permission !== 'granted') Notification.requestPermission();
  const notification = new Notification(title, {
    icon: require('@/assets/images/opens-app-logo-for-notification.png'),
    body: message,
  });
  notification.onclick = function (e) {
    parent.focus();
    //window.focus(); //just in case, older browsers
    e.target.close();
  };
}
