<template lang="pug">
div
  .context-area(v-if="!loading")
    .callback-aligner
      callback-modal(
        v-if="actualModal == 'assignCallback'",
        :contact="callbackContact",
        @add-callback-event="addCallbackEvent",
        @close-modal="actualModal = ''",
      )
    transition(name="fade")
      overlay-edit-contact(
        v-if="overlay == 'edit'",
        :contact-id="this.$store.getters['rh/GET_CONTACT'].id"
        @close-overlay="overlay = null"
      )
    transition(name="fade")
      overlay-add-contact(
        v-if="overlay == 'add'",
        :contact-id="this.$store.getters['rh/GET_CONTACT'].id"
        @close-overlay="overlay = null"
      )
    overlay-historic(
      v-if="overlay == 'history'",
      :contact-id="this.$store.getters['rh/GET_CONTACT'].id"
      @close-overlay="overlay = null"
    )
    movidesk-overlay-tickets(
      v-if="overlay == 'movidesk-tickets'"
      @close-overlay="overlay = null"
    )
    .pt-8.m-0.pagearea
      success-modal(
        v-if="this.$store.getters['GET_MODAL'] === 'AddCallbackSuccess'"
        :modalText="modals.AddCallbackSuccess.modalText"
      )
      failed-modal(
        v-if="this.$store.getters['GET_MODAL'] === 'AddCallbackFailed'"
        :modalText="modals.AddCallbackFailed.modalText"
      )
      .justify-between.header.margin-header
        .flex.justify-center.gap-3
          .flex.items-center.gap-2
            a.mb-0.title Chamada em andamento
            span.text-gray-500.transition-all.cursor-pointer.material-icons.notranslate(
              class="hover:text-brand-400 active:scale-90",
              v-tooltip="'Recarregar informações'",
              @click="initData"
            ) refresh
          .relative.mt-2.cursor-help(
            v-if="isCallBeingSpied",
            v-tooltip="'Alguém está sussurrando a chamada'",
          ) 
            .absolute.material-icons.notranslate.text-brand.animate-ping visibility
            .material-icons.notranslate.text-brand visibility
        div
          v-button(
            v-if="!isLoadingSave",
            @click="save",
            :disabled="!isCallFinished"
          ) Concluir
          loading-ring.pr-4(
            v-else
          )
      .context-area-content
        left-side(
          @open-overlay="openOverlay"
          @open-callback="openCallback"
          @tag-event="setTag"
          @note-event="setNote"
          :activities="activities"
        )
        right-side(
          @open-overlay="openOverlayRight"
          @call-activities="sendActivities"
        )
  .w-full.h-screen.pt-10.context-area(v-else)
    .flex.items-center.justify-start.gap-2
      a.pl-8.mb-0.title Chamada em andamento
      span.text-gray-500.transition-all.cursor-pointer.material-icons.notranslate(
        class="hover:text-brand-400 active:scale-90"
        v-tooltip="'Recarregar informações'"
        @click="initData"
      ) refresh
    content-placeholders.flex.items-start.gap-4.p-10(:rounded="true")
      content-placeholders-img.h-48(class="w-2/5 min-w-[394px]")
      .w-full
        .mt-6.mb-2.context-area-subtitle Rastreio de atividades
        content-placeholders-img.w-full.h-10
</template>
<script>
import MovideskOverlayTickets from './ContextArea.Movidesk.OverlayTickets.vue';
import VButtonSecondary from '@/components/Buttons/v-button-secondary.vue';
import CallbackModal from '@/pages/Contacts/Contact.CallbackModal.vue';
import OverlayEditContact from './ContextArea.OverlayEditContact.vue';
import OverlayAddContact from './ContextArea.OverlayAddContact.vue';
import DropdownItem from '@/components/Dropdown/dropdown-item.vue';
import OverlayHistoric from './ContextArea.OverlayHistoric.vue';
import SuccessModal from '@/components/Modals/SuccessModal.vue';
import UserOrContactMixin from '@/mixins/user-or-contact.mixin';
import FailedModal from '@/components/Modals/FailedModal.vue';
import integrationsService from '@/services/integrations';
import VButton from '@/components/Buttons/v-button.vue';
import { Dropdown, hideAllPoppers } from 'floating-vue';
import LoadingRing from '@/components/LoadingRing.vue';
import { getAllCallTags } from '@/services/call-report';
import RightSide from './ContextArea.RightSide.vue';
import LeftSide from './ContextArea.LeftSide.vue';
import cservice from '@/services/cservice';
import EventBus from '@/main-event-bus';
import { SessionState } from 'sip.js';
import rship from '@/services/rship';

export default {
  title: 'Opens - Area de contexto',
  mixins: [UserOrContactMixin],
  components: {
    MovideskOverlayTickets,
    VDropdown: Dropdown,
    OverlayEditContact,
    OverlayAddContact,
    VButtonSecondary,
    OverlayHistoric,
    DropdownItem,
    CallbackModal,
    SuccessModal,
    FailedModal,
    LoadingRing,
    RightSide,
    LeftSide,
    VButton,
  },
  data() {
    return {
      actualModal: '',
      noteValue: '',
      openedByContext: false,
      isLoadingSave: false,
      loading: false,
      saved: false,
      callbackContact: null,
      noteTimeout: null,
      overlay: null,
      modals: {
        AddCallbackSuccess: {
          modalText: 'Retorno atribuído com sucesso.',
        },
        AddCallbackFailed: {
          modalText: 'Falha ao atribuir retorno.',
        },
      },
      callData: {},
      noteActivity: {},
      tagActivity: {},
      tagValue: [],
      activities: [],
    };
  },
  watch: {
    identityContact(value) {
      if (!value) return;
      this.updateRhStoreContact();
    },
    async linkedid(nextValue, prevValue) {
      this.loading = true;
      if (nextValue === prevValue) return;
      try {
        await this.saveRelationShip();
      } catch (e) {
        this.$log.info(e);
      } finally {
        this.clearData();
        await this.initData();
        this.loading = false;
      }
    },
    async activeModal(newValue, oldValue) {
      if (oldValue !== 'TagChat') return;
      try {
        const currentTags = await getAllCallTags({
          companyId: this.user.company_id,
          linkedId: this.linkedid,
        });
        if (currentTags.length === 0) return;
        this.$store.commit('attendanceState/SET_CURRENT_CALL_ATTENDANCE_REQUIRES_TAG', false);
      } catch (e) {
        console.error(e);
      }
    },
  },
  computed: {
    rawCallerNumber() {
      const fromId = this.callData?.fromId || this.callData?.from_id;
      const isMineCall = fromId === this.user.id || this.callData.from === this.user.endpoint;

      return isMineCall ? this.callData.to : this.callData.from;
    },
    data() {
      return this.$store.getters['cstate/GET_CALL_PAYLOAD'];
    },
    linkedid() {
      return this.$route.params.callId;
    },
    isCallFinished() {
      return (
        this.$store.getters['cstate/GET_CURRENT_CALL'] === null ||
        this.$store.getters['sip/sessionState'] === SessionState.Terminated ||
        !this.isWebRTC
      );
    },
    isCallBeingSpied() {
      return this.$store.getters['cstate/spier'];
    },
    configuration() {
      return this.$store.getters['sip/configuration'];
    },
    agentHasToFillMandatoryTagAfterCall() {
      return this.$store.getters['attendanceState/CURRENT_CALL_ATTENDANCE_REQUIRES_TAG'];
    },
    activeModal() {
      return this.$store.getters['modal/activeModal'];
    },
  },
  methods: {
    async initData() {
      this.$store.commit('attendanceState/SET_CURRENT_CALL_ATTENDANCE_REQUIRES_TAG', false);
      this.loading = true;
      try {
        this.callData = this.$store.getters['cstate/GET_CALL_PAYLOAD'] || {};
        if (!this.callData) return;

        const fromId = this.callData?.fromId || this.callData?.from_id;
        const toId = this.callData?.toId || this.callData?.to_id;
        const isMineCall = fromId === this.user.id || this.callData.from === this.user.endpoint;
        const callerId = isMineCall ? toId : fromId;

        await this.setContactOrOperator({
          id: callerId,
          number: this.rawCallerNumber,
        });

        const { data: mandatoryTag } = await cservice.getCompanieConfigByKey('mandatory-tag');

        this.$store.commit('attendanceState/SET_CURRENT_CALL_ATTENDANCE_REQUIRES_TAG', mandatoryTag?.value === 'true');
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    async save() {
      if (this.isLoadingSave) return this.$toast.warning('Aguarde');
      this.isLoadingSave = true;
      try {
        const currentTags = await getAllCallTags({
          companyId: this.user.company_id,
          linkedId: this.linkedid,
        });

        if (this.agentHasToFillMandatoryTagAfterCall && currentTags.length <= 0) {
          this.$toast.warning('Atribua pelo menos uma tag para concluir o atendimento.');
          this.openTagModal();
          return;
        }

        await this.saveRelationShip();

        this.sendCloseCallWebhook({ callData: this.callData, contact: this.identityContact });

        this.$store.commit('cstate/SET_IS_IN_CONTEXT', false);
        this.$store.commit('attendanceState/SET_CURRENT_CALL_ATTENDANCE_REQUIRES_TAG', false);

        this.$router.push({ name: 'Principal' });
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoadingSave = false;
      }
    },
    openTagModal() {
      this.$store.commit('modal/setActiveModal', 'TagChat');
      this.$store.commit('modal/setContext', {
        callId: this.callData.callid,
        linkedid: this.callData.linkedid || this.callData.uniqueid,
        type: 'call',
        mandatory: this.agentHasToFillMandatoryTagAfterCall,
      });
      hideAllPoppers();
    },
    openCallback(contact) {
      this.callbackContact = contact;
      this.actualModal = 'assignCallback';
      this.$store.commit('SET_ITEMID', contact.id);
    },
    openOverlay(overlay) {
      this.overlay = overlay;
      this.openedByContext = false;
    },
    openOverlayRight(overlay) {
      this.overlay = overlay;
      this.openedByContext = true;
    },
    sendActivities(payload) {
      this.activities = payload;
    },
    setTag(payload) {
      this.tagValue = payload;
    },
    setNote(payload) {
      this.noteValue = payload;
    },
    async addCallbackEvent(payload) {
      await this.$http.post(`${this.requestLinks.rhistory}/event`, {
        eventType: 'callback',
        companyId: this.user.company_id,
        identifier: this.user.username,
        contact: payload,
        callId: this.callData.linkedid,
        text: '',
      });
    },
    async sendCloseCallWebhook({ callData, contact }) {
      try {
        const allRelationshipEventsTags = await rship.getTagsById(callData.linkedid);

        const tags = allRelationshipEventsTags.filter((e) => e.eventType === 'tag');
        const notes = allRelationshipEventsTags.filter((e) => e.eventType === 'note');

        const webhookPayload = {
          linkedId: callData.linkedid || null,
          calldate: callData.calldate || null,
          from: callData.from || null,
          to: callData.to || null,
          uniqueid: callData.uniqueid || null,
          agentPeer: this.configuration?.endpoint,
          agentName: this.user.username,
          agentId: this.user.id,
          contactName: contact?.name,
          contactId: contact?.id,
          contactNumber: contact?.number || this.rawCallerNumber,
          status: callData.status || null,
          tags: tags?.length ? tags?.map((e) => e.text) : null,
          notes: notes?.length ? notes?.map((e) => e.text) : null,
        };

        integrationsService.executeAllActionByHook({
          hook: 'closecall',
          payload: webhookPayload,
          companyId: this.user.company_id,
        });
      } catch (error) {
        console.error(error);
      }
    },
    async saveRelationShip() {
      try {
        if (this.noteValue.length > 0) {
          await this.$http.post(`${this.requestLinks.rhistory}/event`, {
            eventType: 'note',
            companyId: this.user.company_id,
            identifier: this.user.username,
            contact: this.$store.getters['rh/GET_CALLERNAME'],
            callId: this.callData.linkedid,
            text: this.noteValue,
          });
          EventBus.$emit('UX-event', {
            eventName: 'Note Added',
            payload: {
              callId: this.callData.linkedid,
            },
          });
        }
        this.saved = true;
      } catch (e) {
        console.error(e);
        this.$toast.error('Ocorreu algum erro ao salvar as anotações');
      }
    },
    updateRhStoreContact() {
      this.$store.commit('rh/SET_PHONENUMBER', this.identityContact?.number || this.rawCallerNumber);
      this.$store.commit('rh/SET_CALLERNAME', this.identityContact?.name || 'Contato desconhecido');
      this.$store.commit('rh/SET_ORGNAME', this.identityContact?.organization || 'Sem organização');
      this.$store.commit('rh/SET_CONTACT', this.identityContact || {});
      this.$store.commit('rh/SET_AVATAR', this.identityContact?.avatarUrl || null);
      this.$store.commit('rh/SET_TAG', this.identityContact?.tag || null);
    },
    clearData() {
      this.overlay = null;
      this.modals = {
        AddCallbackSuccess: {
          modalText: 'Retorno atribuído com sucesso.',
        },
        AddCallbackFailed: {
          modalText: 'Falha ao atribuir retorno.',
        },
      };
      this.callData = {};
      this.tagValue = [];
      this.noteValue = '';
    },
  },
  async created() {
    await this.initData();
    this.$store.commit('cstate/SET_IS_IN_CONTEXT', true);
  },
};
</script>
<style>
.callback-aligner {
  position: absolute;
  width: 80vw;
  height: 70vh;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.finish-button {
  padding: 9px 16px;
  width: 126px;
  border: none;
  outline: none;
  height: 40px;
  background: var(--brand-color) !important;
  border-radius: 4px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  color: #fff;
  font-weight: normal;
  transition: 0.2s;
  font-size: 14px;
  letter-spacing: 1px;
}
.finish-button:disabled,
.finish-button[disabled] {
  opacity: 0.4;
}
.finish-button:disabled:hover,
.finish-button[disabled]:hover {
  transform: none;
}
.finish-button:hover {
  transform: scale(1.05);
}
.context-area-content {
  display: flex;
  flex-direction: row;
  max-height: 80vh;
}
.margin-header {
  margin-bottom: 32px;
}
.action-context-area {
  width: 249px;
  height: 0px;
  display: none;
  justify-content: flex-start;
  flex-direction: column;
  z-index: 999;
  position: absolute;
  margin-top: 380px;
  background: #ffffff;
  border: 1px solid #e9ecef;
  box-sizing: border-box;
  box-shadow: 0px 7px 16px -12px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  transition: height 0.2s;
}
.context-option {
  cursor: pointer;
  background: #fff;
  width: 247px;
  height: 40px;
  display: none;
  align-items: center;
  padding-left: 24px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #868e96;
  transition: height 0.2s;
}
.context-option:hover {
  background: #f8f9fa;
}
.context-separator {
  margin-top: 10px;
  width: 247px;
  height: 1px;
  background: #f8f9fa;
}
.option-text {
  margin-top: 10px;
  margin-left: 21px;
  display: none;
  font-family: 'Inter', sans-serif;
  font-weight: normal;
  font-size: 12px;
  color: #adb5bd;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
