<template lang="pug">
.event-box.h-fit
  .upside
    span.material-icons.notranslate.tag.p-0(class="min-w-[1.5rem] min-h-[1.5rem]") label
    span.event-text.truncate(class="max-w-[22rem]")
      | Tag 
      | {{ tagText }}
      | adicionada
      p.event-info
      | por 
      b {{ data.identifier }}
    span.date-text.ml-auto.max-w-fit(
      v-tooltip="formatTimestamp(data.updatedAt)"
    ) {{ formatTimestamp(data.updatedAt) }}
  .downside

</template>

<script>
import { dateFormatMixin } from '@/mixins/date-format.mixin';
import rhService from '@/services/rship';

export default {
  mixins: [dateFormatMixin],
  props: ['data', 'calldate'],
  data() {
    return {
      tagText: '',
    };
  },
  methods: {
    async loadTagText() {
      this.tagText = this.data?.text;

      try {
        const isTagAnId = !isNaN(Number(this.tagText));
        const tagIdentifier = this.data.identifier;

        if (!isTagAnId || tagIdentifier !== 'Snep') return;

        const tag = await rhService.getTagById(this.tagText);

        this.tagText = tag.text;
      } catch (error) {
        console.error('Error loading tag text', error);
      }
    },
  },
  mounted() {
    this.loadTagText();
  },
};
</script>

<style lang="scss" scoped>
.event-box {
  width: 100%;
  height: 48px;

  .upside {
    display: flex;
    gap: 21px;
    align-items: center;
  }

  .tag {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: grid;
    place-items: center;
    background: #adb5bd;
    color: #fff;
    font-size: 12.5px !important;
  }

  .event-text {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #495057;
  }

  .date-text {
    margin-left: auto;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #868e96;
  }

  .downside {
    margin-left: 10px;
    border-left: 1px solid #e9ecef;
    padding-left: 31px;
    margin-top: 4px;
    height: 22px;
  }

  .event-info {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #495057;
  }
}
</style>
