<template lang="pug">
.call-received(class="dark:bg-gray-100")#callingId
  a.call-received-title(style="color: #adb5bd !important") Recebendo {{ isTransfer ? 'transferência' : 'ligação' }} de
  .call-profile-img-box
    .colega-card-aligner
      .colega-status-disabled.no-margin(v-if="identityContact?.state" style="top: 0%" :class="(data ? 'status-red' : translateCServiceStatus[identityContact?.state])")
    .call-profile-img-icon
      .w-14.h-14.bg-brand-500.bg-opacity-50.rounded.flex.justify-center.items-center.border.border-brand-100(class="dark:invert")
        contact-avatar(
          :identity-contact="identityContact"
          :failed-img="failedImg"
          @failedImg="failedImg = true"
        )

  .call-text-box
    a.calling-dial-name.white-text.text-marker {{ identityContact?.name }}
  .client-text(v-if="identityContact?.tag" style="padding: 5px !important; width: max-content !important; margin-bottom: 2px; margin-top: 0px;") {{ identityContact?.tag }}

  .client-text.Colega.colega-tag.p-1(v-else-if="identityContact?.state") Colega

  a.card-info-text(style="color: #adb5bd !important").text-marker {{ identityContact?.organization }}
  a.card-info-text(style="color: #adb5bd !important").text-marker {{ identityContact?.number || this.from }}
  .call-line-separator(class="dark:bg-gray-300")
  .icons-aligner.flex.justify-evenly.items-center.w-full.h-28
    .left-icon(v-if="!isWebRTC")
      div(style="display: flex; align-items: center; height: 90px")
        span(style="display: flex;   animation: none;   border: 1px dashed var(--green-default);   background: transparent;   width: 72px;   height: 72px;   font-size: 40px !important; " class="accept-call-icon notranslate material-icons") phone
      a.card-info-text(style="color: #adb5bd !important; width: 100px").text-marker Atender no
        a(style="font-weight: bold") Ramal {{ user.endpoint }}
    .flex.gap-10(v-if="!loadingCall")
      .center-icon(v-if="isWebRTC" class="hover:opacity-50 transition-all active:scale-90")
        i.w-14.h-14.accept-call-icon.flex.items-center.justify-center.material-icons.notranslate.mb-4(
          @click="pickupCall" 
          role="button"
          :class="{ '!bg-gray-600 !animate-none opacity-70 cursor-not-allowed': agentHasToFillMandatoryTagAfterCall }"
        ) phone
        a.card-info-text(style="color: #adb5bd !important").text-marker Atender
      .right-icon(v-if="isWebRTC" @click="hangUpCall" class="hover:opacity-50 transition-all active:scale-90")
        .flex.mb-4
          .turnoff-call-icon
            span.material-icons.notranslate(style="font-size: 28px") phone
        a.card-info-text(style="color: #adb5bd !important").text-marker Desligar
    .center-icon(v-else class="hover:opacity-50 transition-all active:scale-90")
      loading-ring
</template>

<script>
import eventBus from '@/main-event-bus';
import ContactAvatar from './ContactAvatar.vue';
import { getUserRingingSound } from '@/services/cservice';
import UserOrContactMixin from '@/mixins/user-or-contact.mixin';
import LoadingRing from '@/components/LoadingRing.vue';

export default {
  mixins: [UserOrContactMixin],
  components: {
    ContactAvatar,
    LoadingRing,
  },
  data() {
    return {
      translateCServiceStatus: {
        enabled: 'Disponivel',
        disabled: 'NaoPerturbe',
        online: 'Disponivel',
        auto: 'Disponivel',
      },
      loadingCall: false,
      failedImg: false,
    };
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    sip() {
      return this.$store.getters['sip/session'];
    },
    isTransfer() {
      return this?.data?.event?.toLowerCase() === 'transfer';
    },
    agentHasToFillMandatoryTagAfterCall() {
      return false;
    },
    fromId() {
      return this.data?.fromId;
    },
    from() {
      return this.data?.from;
    },
  },
  methods: {
    async hangUpCall() {
      if (this.loadingCall) return;
      this.loadingCall = true;
      await this._hangUpCall();
      this.$emit('hangup-call');
    },
    pickupCall() {
      if (this.agentHasToFillMandatoryTagAfterCall || this.loadingCall) return;
      this.loadingCall = true;
      this.sip.session.accept(this.sip.options);
      eventBus.$emit('UX-event', { eventName: 'Dialer Pickup' });
    },
    playAudio() {
      if (!this.isWebRTC) return;
      const volume = this.$store.getters['userPreferences/getAudioVolume'];
      const audio = this.$store.getters['cstate/ringingSound'];
      audio.volume = volume;
      audio.play();
    },
    async identifyCall() {
      await this.setContactOrOperator({
        id: this.fromId,
        number: this.from,
      });
    },
  },
  mounted() {
    try {
      const localStorageUserRingingSound = localStorage.getItem(`@yunique:ringing-sound:${this.user.id}`);
      if (localStorageUserRingingSound) {
        const ringingSounds = this.$store.getters['cstate/ringingSounds'];
        const selectedRingingSound = ringingSounds[localStorageUserRingingSound] || ringingSounds['default'];

        this.$store.commit('cstate/setRingingSound', selectedRingingSound);
      } else {
        getUserRingingSound(this.user.id).then((data) => {
          if (data.ringing_sound) {
            const ringingSounds = this.$store.getters['cstate/ringingSounds'];
            const selectedRingingSound = ringingSounds[data.ringing_sound] || ringingSounds['default'];

            this.$store.commit('cstate/setRingingSound', selectedRingingSound);
            localStorage.setItem(`@yunique:ringing-sound:${this.user.id}`, data.ringing_sound);
          }
        });
      }
    } catch (e) {}
    this.playAudio();
    const audio = this.$store.getters['cstate/ringingSound'];
    audio.addEventListener('ended', this.playAudio);
  },
  beforeDestroy() {
    const audio = this.$store.getters['cstate/ringingSound'];
    audio.removeEventListener('ended', this.playAudio);
    audio.pause();
    audio.currentTime = 0;
  },
  created() {
    this.identifyCall();
  },
  watch: {
    data: {
      handler(v) {
        this.identifyCall();
      },
      immediate: false,
    },
  },
};
</script>

<style>
.calling-dial-name {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}
.card-info-text {
  width: 105px;
  height: 44px;
  text-align: center;
}
.center-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.right-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.left-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ramal-call-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  padding: 0px !important;
  font-size: 24px !important;
  border-radius: 50%;
  color: #fff;
  background: #212529;
}
.reject-call-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  padding: 0px !important;
  font-size: 24px !important;
  border-radius: 50%;
  color: #dc6058;
  background: #212529;
}
.icons-aligner {
  justify-content: space-around;
  margin-top: 50px;
  width: 100%;
  display: flex;
  align-items: baseline;
  flex-direction: row;
}
@keyframes pulse-green {
  0% {
    transform: scale(0.98);
    box-shadow: 0 0 0 0 rgba(88, 220, 165, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(88, 220, 165, 0);
  }

  100% {
    transform: scale(0.98);
    box-shadow: 0 0 0 0 rgba(88, 220, 165, 0);
  }
}
.accept-call-icon {
  font-size: 34px !important;
  padding: 0px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: var(--green-default);
  width: 56px;
  height: 56px;
  border-radius: 50%;
  transform: scale(1);
  animation: pulse-green 2s infinite;
}
.bold-text {
  font-weight: bold;
}
.call-line-separator {
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
  background: #495057;
  height: 1px;
}
.call-text-box {
  margin-top: 25px;
  margin-bottom: 25px;
}
.white-text {
  color: #fff !important;
}
.call-profile-img-box {
  display: flex;
  margin-top: 35px;
  width: 58px;
  height: 58px;
  align-items: flex-end;
  flex-direction: column-reverse;
}
.call-profile-img-icon {
  width: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  background: rgba(var(--brand-color), 33);
  border-radius: 4px;
}
.call-received-title {
  margin-top: 55px;
  font-family: 'Inter', sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #adb5bd;
}
.call-received {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 30px;
  position: absolute;
  top: 0;
  z-index: 1;
  background: rgba(52, 58, 64, 0.95);
  width: 100%;
  height: 100%;
}
</style>
